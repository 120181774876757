import { LegacyAny } from '@soracom/shared/core';

import { Subject } from 'rxjs';
import { ScRelation } from '../components/paginator';
import {
  LegacyBaseSoracomApiService,
  PaginatableService,
  PaginationService,
  TaggableService,
  TagParams,
} from '@user-console/legacy-soracom-api-client';
import { TerminatableService } from '@soracom/shared/soracom-platform';
import { InjectList } from '../core/injectable';
import { SoraCamCellularPack } from '../core/sora_cam_cellular_pack';

/**
 * SoraCam Cellular Pack is handled as a kind of gadgets.
 */
export class SoraCamCellularPacksService
  implements TaggableService, PaginatableService<SoraCamCellularPack>, TerminatableService
{
  static $inject: InjectList = ['$q', '$log', 'BaseSoracomApiService', 'PaginationService'];

  resourcePath = 'gadgets';

  productId = 'wimax';

  constructor(
    private $q: ng.IQService,
    private $log: ng.ILogService,
    private SoracomApi: LegacyBaseSoracomApiService,
    private PaginationService: PaginationService,
  ) {
    this.$log.debug('SoraCamCellularPacksService constructor()...');
  }

  /**
   * Emit a resource ID or null when resources get updated in the app
   */
  update$ = new Subject<string | null>();

  /**
   * GET     /v1/gadgets controllers.GadgetsController.listGadgets(product_id: String ?= null,tag_name: String ?= null, tag_value: String ?= null, tag_value_match_mode: String ?= null, last_evaluated_key: String ?= null, limit: Integer ?= -1)
   *
   * [preliminary API docs](https://scrapbox.soracom.io/general/gadgets_API)
   */
  list(queryOptions: LegacyAny): Promise<ScRelation<SoraCamCellularPack>> {
    const apiParams = {
      method: 'get',
      path: '/v1/gadgets',
      query: {
        product_id: this.productId,

        // tag_name: queryOptions.tag_name,
        // tag_value: queryOptioan.tag_value,
        // tag_value_match_mode: queryOptions.tag_value_match_mode
        // Mason: v1 console implementation doesn't allow searching like SIM UI does

        limit: queryOptions.limit,
        last_evaluated_key: queryOptions.last_evaluated_key,
      },
    };

    return this.SoracomApi.callApiWithToken(apiParams).then((response: LegacyAny) => {
      const data = response.data.map((element: LegacyAny) => new SoraCamCellularPack(element));
      const relation: ScRelation<SoraCamCellularPack> = {
        data,
        links: this.PaginationService.getPaginationLinks(response.headers.link),
      };

      return this.$q.resolve(relation);
    });
  }

  /**
   * PUT /v1/gadgets/wimax/:id/tags
   */
  updateTags(id: string, tags: TagParams[]) {
    const apiParams = {
      method: 'put',
      // path: `/v1/${this.resourcePath}/${this.productId}/${id}/tags`,
      path: `/v1/${this.resourcePath}/${id}/tags`,
      contentType: 'application/json',
      body: tags,
    };
    return this.SoracomApi.callApiWithToken(apiParams).then((response: LegacyAny) => {
      this.update$.next(id);
      return response;
    });
  }

  /**
   * DELETE  /v1/gadgets/wimax/:id/tags/:tag_name
   */
  deleteTag(id: string, tagName: string) {
    const apiParams = {
      method: 'delete',
      path: `/v1/${this.resourcePath}/${id}/tags/${encodeURIComponent(tagName)}`,
    };
    return this.SoracomApi.callApiWithToken(apiParams).then((response: LegacyAny) => {
      this.update$.next(id);
      return response;
    });
  }

  /**
   * POST    /v1/gadgets/:product_id/:serial_number/terminate
   */
  terminate(id: string): Promise<SoraCamCellularPack> {
    const apiParams = {
      method: 'post',
      path: `/v1/${this.resourcePath}/${id}/terminate`,
      contentType: 'application/json',
    };
    return this.SoracomApi.callApiWithToken(apiParams).then((response: LegacyAny) => {
      this.update$.next(id);
      return new SoraCamCellularPack(response.data);
    });
  }

  /**
   * POST    /v1/gadgets/wimax/:id/disable_termination
   */
  disableTermination(id: string): Promise<SoraCamCellularPack> {
    const apiParams = {
      method: 'post',
      path: `/v1/${this.resourcePath}/${id}/disable_termination`,
      contentType: 'application/json',
    };
    return this.SoracomApi.callApiWithToken(apiParams).then((response: LegacyAny) => {
      this.update$.next(id);
      return new SoraCamCellularPack(response.data);
    });
  }

  /**
   * POST    /v1/gadgets/wimax/:id/enable_termination
   */
  enableTermination(id: string): Promise<SoraCamCellularPack> {
    const apiParams = {
      method: 'post',
      path: `/v1/${this.resourcePath}/${id}/enable_termination`,
      contentType: 'application/json',
    };
    return this.SoracomApi.callApiWithToken(apiParams).then((response: LegacyAny) => {
      this.update$.next(id);
      return new SoraCamCellularPack(response.data);
    });
  }

  get(id: string) {
    const apiParams = {
      method: 'get',
      path: `/v1/${this.resourcePath}/${id}`,
      contentType: 'application/json',
    };
    return this.SoracomApi.callApiWithToken(apiParams).then(
      (response: LegacyAny) => new SoraCamCellularPack(response.data),
    );
  }
}
