import { Gadget } from './gadget';

export interface SoraCamCellularPackAttributes {
  wimaxIccid: string;
  /** as stringified JSON array */
  wimaxImei: string;
  /** YYYY-MM-DD */
  wimaxBillingStartDate: string;
}

export class SoraCamCellularPack extends Gadget {
  static productId: 'wimax';

  // @ts-expect-error (legacy code incremental fix)
  attributes: SoraCamCellularPackAttributes;
  // @ts-expect-error (legacy code incremental fix)
  iccid: string;
  /** The list of IMEI of home routers which is assigned to the ICCID */
  // @ts-expect-error (legacy code incremental fix)
  imeiList: string[];

  get type() {
    return this.productId;
  }

  get msisdn() {
    return this.serialNumber;
  }

  get billingStartDate() {
    return this.attributes?.wimaxBillingStartDate;
  }

  updateFrom(src: any) {
    super.updateFrom(src);
    this.attributes = src.attributes || {};
    this.iccid = this.attributes.wimaxIccid;
    this.imeiList = JSON.parse(this.attributes.wimaxImei);
  }
}
